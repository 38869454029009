import React, { useContext, useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NotificationContext } from "../context/NotificationContext";
import { motion } from "framer-motion";

const NotificationAlert = React.memo(() => {
	const location = useLocation();
	const { lastNotification, setLastNotification } = useContext(NotificationContext);
	const navigate = useNavigate();
	const [animate, setAnimate] = useState(false);
	const timeoutRef = useRef(null);

	const handleClick = useCallback(() => {
		navigate("/betRadar");
	}, [navigate]);

	useEffect(() => {
		if (setLastNotification) {
			setAnimate(true);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				setAnimate(false);
				timeoutRef.current = null;
			}, 500);

			return () => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
			};
		}
	}, [setLastNotification]);

	if (location.pathname === "/betRadar") {
		return null;
	}

	return (
		<>
			{lastNotification ? (
				<motion.button onClick={handleClick} whileTap={{ scale: 0.9 }} id="notification-btn" animate={animate ? { x: [0, -5, 5, -5, 5, 0] } : {}} transition={{ duration: 0.3 }}>
					<div className="notification-btn-inside">
						<span className="emoji">🏆</span>
						<div className="notification-btn-details">
							<p>
								<span>
									{lastNotification.category} - {lastNotification.marketLabel}
								</span>
							</p>
							<span className="bet">{lastNotification.bet}</span>
						</div>
					</div>
				</motion.button>
			) : null}
		</>
	);
});

export default NotificationAlert;
