import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { NotificationContext } from "../context/NotificationContext";
import NotificationHeader from "./NotificationHeader"; // Affichage du header
import NotificationDetails from "./NotificationDetails"; // Détails du pari
import NotificationBottom from "./NotificationBottom"; // Sélection du bookmaker
import NotificationCardFooter from "./NotificationCardFooter "; // Boutons d'actions
import NotificationEdit from "./NotificationEdit"; // Mode édition
import NotificationSuccess from "./NotificationSuccess"; // Confirmation d’un pari pris
import NotificationBanned from "./NotificationBanned"; // Gestion d’un pari banni
import NotificationNotTaken from "./NotificationNotTaken"; // Gestion d’un pari refusé
import NotificationAutoRemove from "./NotificationAutoRemove"; // Suppression automatique de la notification.

const NotificationCard = ({ t, notification, onBan, onTake, onNotTaken }) => {
	const countdownValue = 10;

	// États principaux
	const [isNew, setIsNew] = useState(true);
	const [isBanned, setIsBanned] = useState(false);
	const [isTaken, setIsTaken] = useState(false);
	const [isNotTaken, setIsNotTaken] = useState(false);
	const [editing, setEditing] = useState(false);
	const [showCancel, setShowCancel] = useState(false);
	const [selectingBookmaker, setSelectingBookmaker] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const [isTakenStatus, setIsTakenStatus] = useState("idle");
	const [isBanStatus, setIsBanStatus] = useState("idle");

	// Données du pari
	const [selectedBook, setSelectedBook] = useState(null);
	const [selectedBookName, setSelectedBookName] = useState(null);
	const [selectedBookmaker, setSelectedBookmaker] = useState(null);
	const [customOdd, setCustomOdd] = useState(1.0);
	const [betAmount, setBetAmount] = useState(notification.betToPlace ?? 0);
	const [countdown, setCountdown] = useState(countdownValue);
	const [countdownReseted, setCountdownReseted] = useState(false);

	// Contexte
	const { setLastNotification } = useContext(NotificationContext);

	// Références
	const countdownRef = useRef(null);
	const timeoutRef = useRef(null);

	// Mémos
	const softValuesMap = useMemo(() => {
		return notification.softValues.reduce((acc, softValue) => {
			acc[softValue.book] = softValue;
			return acc;
		}, {});
	}, [notification.softValues]);

	// Callbacks
	const resetState = useCallback(() => {
		setIsBanned(false);
		setIsNotTaken(false);
		setIsTaken(false);
		setShowCancel(false);
		setSelectingBookmaker(false);
		setEditing(false);
		setCountdownReseted(false);
		setCountdown(countdownValue);
		setSelectedBook(null);
		setSelectedBookName(null);
		setCustomOdd(1.0);
		setInProgress(false);
		setSelectedBookmaker(null);
		setLastNotification(null);
		setIsTakenStatus("idle");
		setIsBanStatus("idle");
	}, [countdownValue]);

	const handleSelectBookmaker = useCallback(
		(book) => {
			if (!book || Object.keys(softValuesMap).length === 0) return;

			const selectedSoftValue = softValuesMap[book] ?? { odd: 1.0 };
			setSelectedBook(book);
			setSelectedBookName(selectedSoftValue.bookName);
			setCustomOdd(selectedSoftValue.odd);
			setBetAmount(notification.betToPlace ?? 0);
			setIsTaken(true);
		},
		[softValuesMap, notification.betToPlace]
	);

	const handleTake = useCallback(() => {
		setShowCancel(true);
		if (notification.softValues.length > 1) {
			setSelectingBookmaker(true);
		} else {
			handleSelectBookmaker(notification.softValues[0].book);
		}
	}, [notification.softValues, handleSelectBookmaker]);

	const handleEdit = useCallback(() => {
		setCountdownReseted(true);
		setEditing(true);
	}, []);

	const handleValidateEdit = useCallback(() => {
		setCountdownReseted(false);
		setEditing(false);
		setCountdown(countdownValue);
	}, [countdownValue]);

	const handleCancel = useCallback(() => {
		resetState();
	}, [resetState]);

	const handleBan = useCallback(() => {
		if (isBanned || isTaken || isNotTaken) return;
		setIsBanned(true);
		setShowCancel(true);
		setCountdown(countdownValue);
	}, [isBanned, isTaken, isNotTaken, countdownValue]);

	const handleNotTaken = useCallback(() => {
		if (isBanned || isTaken || isNotTaken) return;
		setIsNotTaken(true);
		setShowCancel(true);
		setCountdown(countdownValue);
	}, [isBanned, isTaken, isNotTaken, countdownValue]);

	// Query
	const updateNotificationValue = async (valueToUpdate) => {
		try {
			const response = await axios.put("/bet/notification", valueToUpdate);
			return response.status === 200 && response.data.code === "ok";
		} catch (err) {
			console.error(err);
			return false;
		}
	};

	const banNotificationValue = async () => {
		try {
			const userId = JSON.parse(localStorage.getItem("user")).id;
			const response = await axios.put("/bet/ban", { id: notification.id, userId: userId });

			return response.status === 200 && response.data.code === "ok";
		} catch (err) {
			console.error(err);
			return false;
		}
	};

	const handleTakeQuery = useCallback(async () => {
		setInProgress(true);
		setIsTakenStatus("idle");

		const valueToUpdate = {
			id: notification.id,
			bet: betAmount.toString(),
			odd: customOdd.toString(),
			book: selectedBook,
		};

		const updatedValue = await updateNotificationValue(valueToUpdate);

		if (!updatedValue) {
			setIsTakenStatus("error");
			timeoutRef.current = setTimeout(() => {
				resetState();
			}, 2000);
			return;
		}

		setIsTakenStatus("success");
		timeoutRef.current = setTimeout(() => {
			onTake(notification.id);
		}, 2000);
	}, [notification.id, betAmount, customOdd, selectedBook, onTake, resetState]);

	const handleBanQuery = useCallback(async () => {
		setInProgress(true);
		setIsBanStatus("idle");

		const bannedValue = await banNotificationValue();

		if (!bannedValue) {
			setIsBanStatus("error");
			timeoutRef.current = setTimeout(() => {
				resetState();
			}, 2000);
			return;
		}

		setIsBanStatus("success");
		timeoutRef.current = setTimeout(() => {
			onBan(notification.id);
		}, 2000);
	}, [notification.id, onBan, resetState]);

	// Effets
	useEffect(() => {
		if ((isBanned || isTaken || isNotTaken) && !countdownReseted) {
			countdownRef.current = setInterval(() => {
				setCountdown((prev) => {
					if (prev <= 1) {
						clearInterval(countdownRef.current);
						countdownRef.current = null;
						if (isBanned) handleBanQuery();
						if (isNotTaken) onNotTaken(notification.id);
						if (isTaken) handleTakeQuery();
						return 0;
					}
					return prev - 1;
				});
			}, 1000);

			return () => {
				if (countdownRef.current) {
					clearInterval(countdownRef.current);
					countdownRef.current = null;
				}
			};
		}
	}, [isBanned, isTaken, isNotTaken, countdownReseted, onBan, onNotTaken, onTake, handleTakeQuery, handleBanQuery, notification.id, selectedBook, customOdd, betAmount]);

	useEffect(() => {
		if (!notification.sharedDate) return;

		const NEW_NOTIFICATION_TIMEOUT_MS = 10000;
		const sharedTimestamp = new Date(notification.sharedDate).getTime();
		const now = Date.now();
		const elapsed = now - sharedTimestamp;

		if (elapsed >= NEW_NOTIFICATION_TIMEOUT_MS) {
			setIsNew(false);
		} else {
			setIsNew(true);
			const remainingTime = NEW_NOTIFICATION_TIMEOUT_MS - elapsed;

			const timer = setTimeout(() => {
				setIsNew(false);
			}, remainingTime);

			return () => clearTimeout(timer);
		}
	}, [notification.sharedDate]);

	useEffect(() => {
		return () => {
			if (countdownRef.current) {
				clearInterval(countdownRef.current);
				countdownRef.current = null;
			}
		};
	}, []);

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return (
		<motion.div
			className={`motion-notification-card ${isNew ? "new" : ""}`}
			initial={{ opacity: 0, scale: 0.9, y: 20 }}
			animate={{ opacity: 1, scale: 1, y: 0 }}
			exit={{ opacity: 0, scale: 0.9, y: -20 }}
			transition={{ duration: 0.3, ease: "easeOut" }}>
			<div className="notification-card">
				<svg className={`svg ${isNew ? "active" : ""}`} height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
					<rect className="line" height="100%" width="100%" strokeLinejoin="round" />
				</svg>

				<NotificationHeader notification={notification} t={t} />

				<NotificationDetails notification={notification} t={t} />

				{!inProgress && (
					<>
						<NotificationBottom notification={notification} selectingBookmaker={selectingBookmaker} handleSelectBookmaker={handleSelectBookmaker} selectedBookmaker={selectedBookmaker} setSelectedBookmaker={setSelectedBookmaker} t={t} />

						<NotificationCardFooter
							isBanned={isBanned}
							isTaken={isTaken}
							isNotTaken={isNotTaken}
							showCancel={showCancel}
							selectingBookmaker={selectingBookmaker}
							onTake={handleTake}
							onNotTaken={handleNotTaken}
							onBan={handleBan}
							onCancel={handleCancel}
							t={t}
						/>

						{editing && (
							<NotificationEdit
								selectedBook={selectedBook}
								customOdd={customOdd}
								betAmount={betAmount}
								softValues={notification.softValues}
								onSelectBookmaker={handleSelectBookmaker}
								onSetCustomOdd={setCustomOdd}
								onSetBetAmount={setBetAmount}
								onValidateEdit={handleValidateEdit}
								t={t}
							/>
						)}
					</>
				)}

				{isTaken && !editing && (
					<NotificationSuccess selectedBook={selectedBookName} customOdd={customOdd} betAmount={betAmount} countdown={countdown} isTakenStatus={isTakenStatus} onEdit={handleEdit} t={t} />
				)}

				{isBanned && <NotificationBanned countdown={countdown} isBanStatus={isBanStatus} t={t} />}
				{isNotTaken && <NotificationNotTaken countdown={countdown} t={t} />}

				<NotificationAutoRemove notification={notification} t={t} />
			</div>
		</motion.div>
	);
};

export default NotificationCard;
