import React, { createContext, useState, useRef, useEffect, useCallback } from "react";
import { io } from "socket.io-client";

// Composants
// import CustomAlert from "../components/CustomAlert";

// Son de notification
const notificationSound = new Audio("/sounds/notification.mp3");

// Contexte
export const NotificationContext = createContext();

const SOCKET_SERVER_URL = process.env.NODE_ENV === "production" ? "https://api.bet-set-match.com/" : "http://localhost:3001/";
const activeSocketLogs = true;

// Initialisation unique de la socket (évite les recréations inutiles)
const socket = io(SOCKET_SERVER_URL, {
	reconnection: true,
	reconnectionAttempts: 5,
	reconnectionDelay: 1000,
	reconnectionDelayMax: 5000,
	randomizationFactor: 0.5,
});

export const NotificationProvider = ({ children, isActive, userId }) => {
	// États
	const [notifications, setNotifications] = useState([]);
	const [unreadBetNotifications, setUnreadBetNotifications] = useState(0);
	const [lastNotification, setLastNotification] = useState(null);
	// const [alertOptions, setAlertOptions] = useState(null);
	const [isSocketConnected, setIsSocketConnected] = useState(false);
	const [isViewingNotifications, setIsViewingNotifications] = useState(false);
	const isViewingNotificationsRef = useRef(isViewingNotifications);

	// Callbacks
	const joinRoom = useCallback((storedUserId) => {
		if (storedUserId) {
			socket.emit("joinRoom", { userId: storedUserId });
			setIsSocketConnected(true);
			if (activeSocketLogs) console.log(`🔗 Rejoint la room avec l'ID utilisateur : ${storedUserId}`);
		}
	}, []);

	// Effets
	useEffect(() => {
		isViewingNotificationsRef.current = isViewingNotifications;
	}, [isViewingNotifications]);

	useEffect(() => {
		const cleanupExpiredNotifications = () => {
			setNotifications((prevNotifications) => {
				const now = Date.now();
				const updatedNotifications = prevNotifications.filter(n => {
					const sharedTimestamp = new Date(n.sharedDate).getTime();
					const expirationTimestamp = sharedTimestamp + 300000; // 5 minutes
					
					const isNotExpirated = now < expirationTimestamp;

					if (!isNotExpirated) setLastNotification(null);
					
					return isNotExpirated;
				});

				if (!isViewingNotificationsRef.current) {
					setUnreadBetNotifications(updatedNotifications.filter((n) => !n.isSeen).length);
				}

				return updatedNotifications;
			});
		};
	
		const interval = setInterval(cleanupExpiredNotifications, 1000);
	
		return () => clearInterval(interval);
	}, []);	

	useEffect(() => {
		if (!isActive || userId === null) return;
		
		const handleNotification = (data) => {
			//if (activeSocketLogs) console.log("🔔 Notification reçue :", data);
			notificationSound.play().catch((error) => console.error("🔊 Erreur lors de la lecture du son :", error));

			setNotifications((prevNotifications) => {
				const notificationExists = prevNotifications.some((n) => n.id === data.id);
				const updatedNotifications = notificationExists ? prevNotifications.map((n) => (n.id === data.id ? data : n)) : [...prevNotifications, data];

				if (!isViewingNotificationsRef.current) {
					setUnreadBetNotifications(updatedNotifications.filter((n) => !n.isSeen).length);
					setLastNotification(data);
				}

				return updatedNotifications;
			});
		};

		const handleConnect = () => {
			if (activeSocketLogs) console.log("✅ Connecté au serveur avec l'ID :", socket.id);
			setIsSocketConnected(true);
			if (userId) joinRoom(userId);
		};

		const handleDisconnect = (reason) => {
			if (activeSocketLogs) console.log(`❌ Déconnecté du serveur : ${reason}`);
			setIsSocketConnected(false);
			if (reason === "io server disconnect") socket.connect();
		};

		const handleReconnectAttempt = (attempt) => {
			if (activeSocketLogs) console.log(`🔄 Tentative de reconnexion n°${attempt}...`);
		};

		const handleReconnect = (attempt) => {
			if (activeSocketLogs) console.log(`✅ Reconnecté après ${attempt} tentative(s).`);
			setIsSocketConnected(true);
			if (userId) joinRoom(userId);
		};

		const handleFailed = () => {
			if (activeSocketLogs) console.log("La reconnexion au système de notifications a échoué. Veuillez rafraîchir la page pour tenter de rétablir la connexion.");
			// setAlertOptions({
			// 	title: "Erreur de connexion",
			// 	message: "La reconnexion au système de notifications a échoué. Veuillez rafraîchir la page pour tenter de rétablir la connexion.",
			// 	onConfirm: () => window.location.reload(),
			// 	onCancel: () => setAlertOptions(null),
			// });
		};

		// Écouteurs d'événements
		socket.on("notification", handleNotification);
		socket.on("connect", handleConnect);
		socket.on("disconnect", handleDisconnect);
		socket.io.on("reconnect_attempt", handleReconnectAttempt);
		socket.io.on("reconnect", handleReconnect);
		socket.io.on("reconnect_failed", handleFailed);

		// Rejoindre la room à l'initialisation
		joinRoom(userId);

		// Nettoyage des écouteurs à l'unmount
		return () => {
			socket.off("notification", handleNotification);
			socket.off("connect", handleConnect);
			socket.off("disconnect", handleDisconnect);
			socket.io.off("reconnect_attempt", handleReconnectAttempt);
			socket.io.off("reconnect", handleReconnect);
			socket.io.off("reconnect_failed", handleFailed);
		};
	}, [userId]);

	return (
		<NotificationContext.Provider
			value={{
				notifications,
				setNotifications,
				unreadBetNotifications,
				setUnreadBetNotifications,
				setIsViewingNotifications,
				isSocketConnected,
				lastNotification,
				setLastNotification,
				socket,
			}}>
			{children}
			{/* {alertOptions && <CustomAlert title={alertOptions.title} message={alertOptions.message} onConfirm={alertOptions.onConfirm} onCancel={alertOptions.onCancel} />} */}
		</NotificationContext.Provider>
	);
};
