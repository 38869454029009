const NotificationEdit = ({ selectedBook, customOdd, betAmount, softValues, onSelectBookmaker, onSetCustomOdd, onSetBetAmount, onValidateEdit, t }) => {
	return (
		<div className="notification-card__edit">
			<div>
				<label>
					{t("Bookmaker")} :
					<select value={selectedBook} onChange={(e) => onSelectBookmaker(e.target.value)}>
						{softValues.map((softValue, index) => (
							<option key={index} value={softValue.book}>
								{softValue.bookName}
							</option>
						))}
					</select>
				</label>
				<label>
					{t("Côte")} :
					<input type="number" step="0.01" value={customOdd} onChange={(e) => onSetCustomOdd(e.target.value)} />
				</label>
				<label>
					{t("Mise")} :
					<input type="number" value={betAmount} onChange={(e) => onSetBetAmount(e.target.value)} />
				</label>
			</div>
			<button onClick={onValidateEdit}>{t("Valider")}</button>
		</div>
	);
};

export default NotificationEdit;
