const NotificationNotTaken = ({ countdown, t }) => (
	<div className="notification-card__not-taken">
		<p>{t("Ce pari n'a pas été pris. Vous pouvez annuler cette action avant la suppression.")}</p>
		<p className="counter-wrapper">
			{t("Suppression de l'affichage dans")} <span>{countdown}</span> {t("seconde")}
			{countdown > 1 ? t("s") : ""}
		</p>
	</div>
);

export default NotificationNotTaken;
