import React, { useEffect, useState, useRef } from "react";

function NotificationAutoRemove({ notification, t }) {
	const intervalRef = useRef(null);
	const timeoutRef = useRef(null);

	const sharedTimestamp = new Date(notification.sharedDate).getTime();
	const expirationTimestamp = sharedTimestamp + 300000; // + 5 minutes

	const calculateTimeLeft = () => Math.max(0, Math.round((expirationTimestamp - Date.now()) / 1000));

	const [secondsLeft, setSecondsLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setSecondsLeft(calculateTimeLeft());
		}, 1000);

		timeoutRef.current = setTimeout(() => {
			clearInterval(intervalRef.current);
		}, expirationTimestamp - Date.now());

		return () => {
			clearInterval(intervalRef.current);
			clearTimeout(timeoutRef.current);
		};
	}, [notification.id]);

	const minutes = Math.floor(secondsLeft / 60);
	const seconds = secondsLeft % 60;
	const formattedTime = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

	return (
		<div className="notification-card__timer">
			<p>
				{t("Suppression automatique dans")} <strong>{formattedTime}</strong>
			</p>
		</div>
	);
}

export default NotificationAutoRemove;
