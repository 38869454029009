import React from "react";
import { FaRegThumbsUp, FaRegThumbsDown, FaTimes } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";

const NotificationCardFooter = ({ isBanned, isTaken, isNotTaken, showCancel, selectingBookmaker, onTake, onNotTaken, onBan, onCancel, t }) => {
	return (
		<div className="notification-card__footer">
			{!isBanned && !isTaken && !isNotTaken && !showCancel && !selectingBookmaker && (
				<>
					<button className="btn-take" onClick={onTake}>
						<FaRegThumbsUp /> {t("J'ai pris")}
					</button>
					<button className="btn-not-taken" onClick={onNotTaken}>
						<FaRegThumbsDown /> {t("Je n'ai pas pris")}
					</button>
					<button className="btn-ban" onClick={onBan}>
						<FaTimes /> {t("Bannir")}
					</button>
				</>
			)}

			{showCancel && (
				<button className="btn-cancel" onClick={onCancel}>
					<TiCancel size={18} /> {t("Annuler")}
				</button>
			)}
		</div>
	);
};

export default NotificationCardFooter;
