const NotificationBanned = ({ countdown, isBanStatus, t }) => (
	<div className="notification-card__banned">
		<p>{t("Ce pari va être banni. Vous pouvez annuler cette action avant la fin de décompte.")}</p>
		<p className="counter-wrapper">
			{isBanStatus === "idle" ? (
				countdown > 0 ? (
					<>
						{t("Banissement du pari dans")} <span>{countdown}</span> {t("seconde")}
						{countdown > 1 ? t("s") : ""}
					</>
				) : (
					<>
						{t("Banissement du pari ...")}
						<span className="loader"></span>
					</>
				)
			) : isBanStatus === "success" ? (
				<p className="success-message">✅ {t("Pari banni avec succès !")}</p>
			) : isBanStatus === "error" ? (
				<p className="error-message">❌ {t("Une erreur s'est produite. Veuillez réessayer.")}</p>
			) : null}
		</p>
	</div>
);

export default NotificationBanned;
