import React, { Fragment } from "react";
import { AiOutlineWarning } from "react-icons/ai";

const NotificationHeader = ({ notification, t }) => (
	<div className="notification-card__top">
		<p className="notification-card__top__title">
			<span>{notification.sport}</span>
		</p>
		{notification.advertiseToAdd && (
			<p className="notification-card__top__warning">
				<AiOutlineWarning style={{ verticalAlign: "text-bottom", fontSize: "18px" }} />
				{notification.advertiseToAdd.split("\n").map((line, index) => (
					<Fragment key={index}>
						{line}
						<br />
					</Fragment>
				))}
			</p>
		)}
	</div>
);

export default NotificationHeader;
