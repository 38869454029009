const NotificationDetails = ({ notification, t }) => {
	return (
		<div className="notification-card__details">
      <div className="notification-card__matchInfo">
        <span>
          {notification.competition} - {notification.date}
        </span>
      </div>
			<div className="notification-card__match">
				<span>{notification.homeTeamName}</span>
				<span className="versus">{t("Vs.")}</span>
				<span>{notification.awayTeamName}</span>
			</div>
			<div className="notification-card__middle">
        <p className="details">{notification.category} - {notification.marketLabel}</p>
				<p>
					<span className="emoji" role="img" aria-label="trophy">
						🏆
					</span>{" "}
					<label>{notification.bet}</label>
				</p>
				<p className="info">
					<span className="betToPlace">
						<span className="emoji" role="img" aria-label="money">
							💰
						</span>{" "}
						{notification.betToPlace}€
					</span>
				</p>
			</div>
		</div>
	);
};

export default NotificationDetails;
