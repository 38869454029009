import React, { useRef, useCallback, useState } from "react";
import { motion } from "framer-motion";

const NotificationBottom = ({ notification, t, selectingBookmaker, handleSelectBookmaker, selectedBookmaker, setSelectedBookmaker }) => {
	const bottomRef = useRef(null);

	const handleBodyOverflowMouseEnter = useCallback(() => {
		document.body.style.overflow = "hidden";
	}, []);

	const handleBodyOverflowMouseLeave = useCallback(() => {
		document.body.style.overflow = "";
	}, []);

	const handleBodyOverflowMouseScroll = useCallback((event) => {
		if (bottomRef.current) {
			bottomRef.current.scrollLeft += event.deltaY;
			event.preventDefault();
		}
	}, []);

	const handleClick = useCallback(
		(book) => {
			setSelectedBookmaker(book);
			handleSelectBookmaker(book);
		},
		[handleSelectBookmaker]
	);

	return (
		<>
			{selectingBookmaker && <h5>Pris sur ...</h5>}
			<div
				className={`notification-card__bottom ${notification.softValues.length > 3 ? "has-scroll" : ""}`}
				ref={bottomRef}
				{...(notification.softValues.length > 3 && {
					onMouseEnter: handleBodyOverflowMouseEnter,
					onMouseLeave: handleBodyOverflowMouseLeave,
					onWheel: handleBodyOverflowMouseScroll,
				})}>
				{notification.softValues.map((softValue, index) =>
					selectingBookmaker ? (
						<motion.button
							key={index}
							className={`notification-card__bottom__btn ${selectedBookmaker === softValue.book ? "selected" : ""}`} 
							onClick={() => handleClick(softValue.book)}
							whileTap={{ scale: 0.9 }}
						>
							<span className="notification-card__bottom__link__book">{softValue.bookName}</span>
							<span>@ {softValue.odd.replace(".", ",")}</span>
						</motion.button>
					) : (
						<a key={index} href={softValue.url} target="_blank" rel="noopener noreferrer" className="notification-card__bottom__link">
							<span className="notification-card__bottom__link__book">{softValue.bookName}</span>
							<span>@ {softValue.odd.replace(".", ",")}</span>
							<span className="notification-card__bottom__link__text">
								{t("TRJ")} : {softValue.trj}
							</span>
							<span className="notification-card__bottom__link__text">
								{t("EV")} : {softValue.ev}
							</span>
						</a>
					)
				)}
			</div>
		</>
	);
};

export default NotificationBottom;
