import React, { useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { NotificationContext } from "../context/NotificationContext";
import NotificationCard from "../components/NotificationCard";

function BetNotifications({ t, isAxiosReady }) {
	const { notifications, setNotifications, setUnreadBetNotifications, setIsViewingNotifications, isSocketConnected, setLastNotification } = useContext(NotificationContext);

	const handleTake = useCallback(
		async (id) => {
			setNotifications((prev) => prev.filter((notification) => notification.id !== id));
		},
		[setNotifications]
	);

	const handleNotTaken = useCallback(
		(id) => {
			setNotifications((prev) => prev.filter((notification) => notification.id !== id));
		},
		[setNotifications]
	);

	const handleBan = useCallback(
		(id) => {
			setNotifications((prev) => prev.filter((notification) => notification.id !== id));
		},
		[setNotifications]
	);

	useEffect(() => {
		setIsViewingNotifications(true);
		setLastNotification(null);

		return () => {
			setNotifications((prevNotifications) => {
				const unseenNotifications = prevNotifications.filter((notification) => notification.isSeen === 0);

				if (unseenNotifications.length > 0 && isAxiosReady) {
					const updateNotificationsInDB = async () => {
						try {
							await axios.put("/bet/mark-seen", {
								ids: unseenNotifications.map((n) => n.id),
							});
						} catch (error) {
							console.error("Erreur lors de la mise à jour des notifications :", error);
						}
					};

					updateNotificationsInDB();
				}

				setUnreadBetNotifications(0);
				return prevNotifications.map((notification) => ({
					...notification,
					isSeen: 1,
				}));
			});

			setIsViewingNotifications(false);
		};
	}, [setIsViewingNotifications, setNotifications, setUnreadBetNotifications, isAxiosReady]);

	return (
		<section id="section-notification">
			<h1>{t("Bet Radar")}</h1>
			<div className="notification-connection-status">
				<span className={`status-indicator ${isSocketConnected ? "connected" : "disconnected"}`}></span>
				<div className="status-text">
					<p>
						{t("Statut : ")} <span className="status-label">{isSocketConnected ? t("Connecté") : t("Déconnecté")}</span>
					</p>
				</div>
			</div>
			<div className="notifications-container">
				{notifications.length > 0 ? (
					notifications
						.slice()
						.sort((a, b) => new Date(b.sharedDate) - new Date(a.sharedDate))
						.map((notification) => <NotificationCard key={notification.id} t={t} notification={notification} onBan={handleBan} onTake={handleTake} onNotTaken={handleNotTaken} />)
				) : (
					<div className="no-notifications">
						<img src="./images/notification-none.png" alt={t("Aucune notification")} />
						<p>{t("Notre radar n'a détecté aucun pari... Un peu de patience ça ne saurait tarder !")}</p>
					</div>
				)}
			</div>
		</section>
	);
}

export default BetNotifications;
