import { FaRegEdit } from "react-icons/fa";

const NotificationSuccess = ({ selectedBook, customOdd, betAmount, countdown, isTakenStatus, onEdit, t }) => (
	<div className="notification-card__success">
		<p>
			{t("Vous avez pris le pari sur")} <span>{selectedBook}</span>, <span>{betAmount}€ </span>
			{t("misé pour une côte de")} <span>{customOdd.replace(".", ",")}</span>.
		</p>
		<p>{t("Vous pouvez modifier ou annuler cette action avant la fin du décompte.")}</p>
		<p className="counter-wrapper">
			{isTakenStatus === "idle" ? (
				countdown > 0 ? (
					<>
						{t("Enregistrement du pari dans")} <span>{countdown}</span> {t("seconde")}
						{countdown > 1 ? t("s") : ""}
					</>
				) : (
					<>
						{t("Enregistrement du pari ...")}
						<span className="loader"></span>
					</>
				)
			) : isTakenStatus === "success" ? (
				<p className="success-message">✅ {t("Pari pris avec succès !")}</p>
			) : isTakenStatus === "error" ? (
				<p className="error-message">❌ {t("Une erreur s'est produite. Veuillez réessayer.")}</p>
			) : null}
		</p>
		<button className="btn-edit" onClick={onEdit} disabled={countdown === 0}>
			<FaRegEdit size={16} /> {t("Éditer")}
		</button>
	</div>
);

export default NotificationSuccess;
